import * as d3 from 'd3';
import * as topojson from "topojson";
// import Splide from '@splidejs/splide';
import Siema from 'siema';
import MicroModal from 'micromodal';
import './style.css'
import Choices from "choices.js";

let translations = {
    "en": {
        "disinfoTitle": "DEBUNKED DISINFORMATION",
        "disinfoHint": "Currently only disinformation circulating in two or more countries is shown.",
        "relatedDebunks": "Related debunks",
        "hintDebunks": "Click on a debunked disinformation card to see when and where our teams have debunked it.",
        "scrollDown": "Scroll down to see more",
        "mapDesc": "This map shows where disinformation about Ukraine's invasion has circulated and has been debunked by IFCN Signatories.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Countries <span class=\"min-color\">█ in shades</span> <span class=\"max-color\">of red</span> represent the amount of disinformation that has been identified and debunked by national fact-checkers in each country.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">When you click on a piece of disinformation, countries in which that hoax has circulated highligh <span class=\"seen-color\">█ in blue</span> and you can access each country's debunking in its language below </p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">When you click on a country you can see which disinformation has been identified and fact-checked in that country and access the national fact-checker’s articles in their language.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Feel free to embed this map in your webpage <span data-micromodal-trigger=\"modal-embed\" style=\"text-decoration: underline;\">through this link</span>",
        "embedTitle": "How to embed",
        "embedDescTop": "To embed this map in your website you just have to paste this lines of HTML code:",
        "embedDescBottom": "You can customize the width of the content by changing the data-with property to the desired width and the default language by setting de data-language property to 'en' for english, or 'es' for spanish. Remember to write px after the desired width.",
        "preposition": "IN"
    },
    "es": {
        "disinfoTitle": "INFORMACIÓN DESMENTIDA",
        "disinfoHint": "Actualmente solo se muestra desinformación que haya circulado en dos o más países.",
        "relatedDebunks": "Desmentidos relacionados",
        "hintDebunks": "Haz click en una tarjeta de desinformación desmentida para ver dónde y cuando la han desmentido nuestros equipos.",
        "scrollDown": "Haz scroll para ver más",
        "mapDesc": "Este mapa muestra en qué países los miembros del International Fact-checking Network han identificado y desmentido desinformación sobre la invasión en Ucrania.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Los países <span class=\"min-color\">█ en tonos</span> <span class=\"max-color\">rojos</span> muestran la cantidad de desinformación identificada y desmentida por los fact-checkers nacionales de cada país.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Al hacer click en una tarjeta de desinformación, se marcarán  <span class=\"seen-color\">█ en azul</span> los países por los que esa desinformación haya circulado. Además, podrás acceder a distintos desmentidos en el idioma propio del país por el que circuló. </p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Al hacer click en un país se mostrará la desinformación que ha sido identificada y desmentida en dicho país y podrás acceder a los desmentidos en el idioma propio.</p><p style=\"font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1\">Si quieres embeber este map en tu web puedes hacerlo <span data-micromodal-trigger=\"modal-embed\" style=\"text-decoration: underline;\"> a través de este enlace</span>",
        "embedTitle": "Cómo embeber",
        "embedDescTop": "Para embeber este mapa en tu web solo tienes que pegar las siguientes líneas de código HTML:",
        "embedDescBottom": "Puedes personalizar el ancho del contenido y el idioma por defecto cambiando las propiedades data-width y data-language. Utiliza el valor 'en' para Inglés y 'es' para Español y recuerda escribir px después del ancho.",
        "preposition": "EN"
    }
};

document.addEventListener('DOMContentLoaded', function () {

    const mainContainer = document.getElementById("ukraine-facts")

    var slideCode = {}
    // Default
    let horizontal = "0";
    if (mainContainer.hasAttribute('data-horizontal')) {
        horizontal = mainContainer.dataset.horizontal;
    }
    if (window.innerWidth <= 1024) {
        horizontal = "0";
    }

    let header = "1";
    if (mainContainer.hasAttribute('data-header')) {
        header = mainContainer.dataset.header;
    }

    // Default language
    let lang = "en";
    if (mainContainer.hasAttribute('data-language')) {
        lang = mainContainer.dataset.language;
    }

    loadPage()

    function loadPage(){
        constructHtml()
        styleMainContainers()
        setLanguage()

        document.getElementById("ukraine-facts").style.maxWidth = document.getElementById("ukraine-facts").dataset.width;
        document.getElementById("ukraine-facts").style.border = "1px solid gray";
        document.getElementById("ukraine-facts").style.padding = "10px";

        constructMap()
    }


    function constructHtml() {
        const mainContainer = document.getElementById("ukraine-facts")
        //Set page content
        mainContainer.innerHTML = ` 
         <div class="modal micromodal-slide" id="modal-embed" aria-hidden="true">
            <div class="modal__overlay" tabindex="-1" data-micromodal-close>
                <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-embed-title">
                    <header class="modal__header">
                        <h2 class="modal__title">
                             ` + translations[lang].embedTitle + `  
                        </h2>
                        <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
                    </header>
                    <div class="modal-content-content">
                        <div class="modal__content">
                           ` + translations[lang].embedDescTop + `  
                            <code style="display: block; padding: 10px; background: whitesmoke; margin: 10px 0;">&#x3C;div id=&#x22;ukraine-facts&#x22; data-width=&#x22;900px&#x22; data-language=&#x22;en&#x22;&#x3E;<br>&#x3C;script src=&#x22;https://ukrainefacts.org/dist/ukraine2022.js&#x22;&#x3E;&#x3C;/script&#x3E;</code>
                            ` + translations[lang].embedDescBottom + ` 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="map-wrap">
            <div id="embed-header"></div>
            <div id="map-container">
            <div id="map">
            <div style="margin: 0;font-family: Fira Sans Condensed,sans-serif;font-size: 17px;cursor:pointer;"><span id="lan-en" >EN</span>/<span id="lan-es">ES</span></div>
            </div>
        <div id="legend">
        </div>
        </div>
        </div>
        <hr>
        <div id="carrousel-wrap">
        </div>`;

        // Add legend
        document.getElementById("legend").innerHTML = `<div>
            <div style="font-size: 14px" class="svitlo"><span class="min-color">█</span> Min: <span
                class="min"></span> <span class="max-color">█</span> Max: <span class="max"></span></div>
            <div style="font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1">
            <p style="font-family: sans-serif; font-size: 12px; color: gray; margin-top: 12px; line-height: 1"> ` + translations[lang].mapDesc + ` </p>
            </div>
        </div>`;

        if (header === "1") {
            document.getElementById("embed-header").innerHTML = `<p style="margin: 0;font-family: Fira Sans Condensed,sans-serif;font-size: 40px;"><b>#UkraineFacts</b></p>
    <a style="line-height: 1;text-decoration: none; color: #383838" href="https://www.ifcncodeofprinciples.poynter.org/" target="_blank"><p style="margin: 0;font-family: Fira Sans Condensed,sans-serif;font-size: 24px;"><b>By the International<br>Fact-checking Network Signatories</b></p></a>
    <a style="line-height: 1;text-decoration: none; color: #858585" href="https://maldita.es/" target="_blank"><p style="margin: 0; font-family: Fira Sans Condensed,sans-serif;font-size: 12px"><b>Developed by Maldita.es</b></p></a>`;
        }

        let imported = document.createElement('script');
        imported.src = 'https://www.googletagmanager.com/gtag/js?id=G-FP3EN5ZTT0';
        document.head.appendChild(imported);

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());

        gtag('config', 'G-FP3EN5ZTT0', {'anonymize_ip': true})

        MicroModal.init({
            awaitCloseAnimation: true,// set to false, to remove close animation
            onShow: function (modal) {
                document.getElementById("carrousel-wrap").style.display = "none";
                console.log("micromodal open");
            },
            onClose: function (modal) {
                document.getElementById("carrousel-wrap").style.display = "block";
                console.log("micromodal close");
            }
        });

        const carrouselWrap = document.getElementById("carrousel-wrap")
        //Construct list
        if(horizontal === "1"){
            carrouselWrap.innerHTML = `<div id="debunked-desinfo">
                                            <p style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 20px"><b>`
                + translations[lang].disinfoTitle +
                `<span style="text-transform: uppercase; display:inline-flex; gap:12px" class="which-country"></span></b>
                                            <span style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 20px" id="total-disinfo"></span>
                                            </p>
                                            <p style="font-size: 11px; font-family: sans-serif; margin: 0;  margin-bottom: 15px; color: darkgray;">`
                + translations[lang].scrollDown +
                `</p>
                                            <select id="searchbar"></select>
                 <p style="font-family: sans-serif; display: none" class="no-disinfo">`
                + translations[lang].disinfoHint +
                `</p>
                                            <div id="carrousel">
                                            </div>
                                       </div>`
        }else{
            carrouselWrap.innerHTML = `<p style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 30px"><b>`
                + translations[lang].disinfoTitle +
                `<span style="text-transform: uppercase; display:inline-flex; gap:12px" class="which-country"></span></b>
                                            <span style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 20px" id="total-disinfo"></span>
                                            </p>
                                            <select id="searchbar"></select>
                                            <p style="font-family: sans-serif; display: none" class="no-disinfo">`
                + translations[lang].disinfoHint +
                `</p>
                                            <div id="carrousel"></div>
                                            <button class="svitlo-btn prev"><</button>
                                            <button style="float:right;" class="svitlo-btn next">></button>
                                            <hr>
                                            <p style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 20px;margin-top:15px;margin-bottom: 15px"><b>`
                + translations[lang].relatedDebunks + `</b></p>
                                            <ul id="desinfo-list" style="list-style-type:disc"";padding: 0"></ul>`

        }
    }

    function styleMainContainers() {

        const mainContainer = document.getElementById("ukraine-facts")
        const carouselWrap = document.getElementById("carrousel-wrap")
        if (horizontal === "1") {
            mainContainer.style.flexDirection = "row"
            document.getElementById("map-wrap").style.width = "50%"
            mainContainer.style.maxWidth = mainContainer.dataset.width;
            mainContainer.style.border = "1px solid gray";
            mainContainer.style.padding = "10px";
            carouselWrap.style.width = "50%"
            carouselWrap.style.position = "relative"
            carouselWrap.style.marginLeft = "24px"
            document.getElementById("carrousel").classList.add("horizontal-slides")
        }
    }

    function constructMap() {
        const searchbar = document.getElementById('searchbar');
        const choices = new Choices(searchbar,{
            searchResultLimit: 1000
        });
        let carrouselWrap = document.getElementById("carrousel");
        let color;

        // Map size
        const width = 850;
        const height = 450

        const svg = d3.select('#map').append('svg').attr("viewBox", "0 0 " + width + " " + height)
            .attr("preserveAspectRatio", "xMinYMin");

        // Load Mercator projection

        const projection = d3.geoMercator().scale(100)
            .translate([width / 2.65, height / 1.4]);
        const path = d3.geoPath(projection);

        const g = svg.append('g');

        // Load data
        d3.json('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json').then(data => {

            // Paint countries
            const countries = topojson.feature(data, data.objects.countries);
            g.selectAll('path').data(countries.features).enter().append('path').attr('class', 'country').attr('d', path)
                .attr("id", function (d) {
                    return "country-" + d.id;
                })
                .attr("data-name", function (d) {
                    return d.properties.name
                        .replaceAll("Bosnia and Herz.", "Bosnia and Herzegovina") // Remove points (bosnia and herz)
                        .replaceAll("United States of America", "United-States") // Simplify name
                        .replaceAll(" ", "-"); // Remove spaces
                });

            // Load our debunks
            d3.json("https://data.maldita.es/ukrainefacts")
                .then(function (data) {

                    let countries = [];
                    let totalDebunks = 0;
                    let totalDisinfo = 0;
                    let searchItems = [];
                    // Add elements to carrousel
                    data.forEach(debunk => {

                        if (debunk.code !== -1 && debunk.featuredImage !== "") {
                            const element = document.createElement('div');

                            let countriesPresent = debunk.ocurrences.map(function (ocurrence) {
                                return ocurrence.country.name;
                            }).join(",").replaceAll(" ", "-") // Remove spaces
                                .replaceAll(".", "") // Remove points (bosnia and herz)
                                .replaceAll("United-States-of-America", "United-States"); // Simplify name;


                            element.id = debunk.code
                            if (horizontal === "1") {
                                element.className = "svitlo-slide-horizontal";
                                element.innerHTML = `<div class="slide-image"><img class="horizontal-image" src="` + debunk.featuredImage + `"/></div>
 <div><div style="line-height: 1;" class="slide-headline">` + debunk.localizedDisinfo[lang] + `</div><div style="font-size: 10px;margin-left:10px;color: gray;width: 100%;font-family: sans-serif;" class="slide-date">` + new Date(debunk.minDate.year, debunk.minDate.month - 1, debunk.minDate.day).toLocaleDateString() + `</div></div>`
                            } else {
                                element.className = "svitlo-slide";
                                element.innerHTML = `<div class="slide-image"><img  style="width:100%" src="` + debunk.featuredImage + `"/></div><div style="font-size: 13px;color: gray;width: 100%;margin-left: 20px;font-family: sans-serif;margin-top: 4px;" class="slide-date">` + new Date(debunk.minDate.year, debunk.minDate.month - 1, debunk.minDate.day).toLocaleDateString() + `</div> <div style="line-height: 1;" class="slide-headline">` + debunk.localizedDisinfo[lang] + `</div>`
                            }

                            element.dataset.countries = countriesPresent;
                            searchItems.push({'value':debunk.code,label:debunk.localizedDisinfo[lang]})

                            element.addEventListener("click", function (x) {
                                showOnList(debunk);
                            }, false);

                            carrouselWrap.append(element);
                            slideCode[debunk.code] = totalDisinfo
                            totalDisinfo++
                        }

                        debunk.ocurrences.forEach(ocurrence => {
                            countries.push(ocurrence.country.name.replaceAll(" ", "-"))
                        })

                        totalDebunks++;
                    });

                    choices.setChoices(searchItems);

                    document.getElementById("total-disinfo").innerText = ": " + totalDebunks + " FACT-CHECKS";

                    if (horizontal === "0") {
                        // Init carrousel
                        const carrousel = new Siema({
                            selector: '#carrousel',
                            perPage: {
                                500: 2,
                                768: 3,
                            },
                        });
                        const prev = document.querySelector('.prev');
                        const next = document.querySelector('.next');

                        prev.addEventListener('click', (event) =>
                        {
                            carrousel.prev()

                        });
                        next.addEventListener('click', () => {
                            carrousel.next()
                        });
                        searchbar.carrousel = carrousel
                    }

                    searchbar.addEventListener(
                        'choice',
                        function(event) {

                            let card = document.getElementById(event.detail.choice.value)
                            card.click()
                            if(horizontal === "0"){
                                searchbar.carrousel.goTo(slideCode[event.detail.choice.value])
                            }
                        },
                        false,
                    );
                    let count = {};
                    countries.forEach(element => {
                        count[element] = (count[element] || 0) + 1;
                    });

                    let min = 1000;
                    let max = 0;

                    for (const [key, value] of Object.entries(count)) {
                        if (value < min)
                            min = value;

                        if (value > max)
                            max = value;
                    }

                    color = d3.scaleLinear()
                        .range(["#e39696", "#b43838"])
                        .domain([min, max])

                    d3.select(".min").text(min);
                    d3.select(".max").text(max);

                    document.querySelectorAll(".min-color").forEach(element => {
                        element.style.color = color(min);
                    });
                    document.querySelectorAll(".max-color").forEach(element => {
                        element.style.color = color(max);
                    });

                    d3.select(".seen-color").style("color", '#1a6985');

                    for (const [key, value] of Object.entries(count)) {

                        let div = d3.select("body").append("div")
                            .attr("class", "tooltip")
                            .style("opacity", 0);

                        try {
                            d3.select("[data-name=" + key + "]").style('fill', function (x) {
                                return color(value)
                            }).on("mouseover", function (event, d) {
                                div.transition()
                                    .duration(200)
                                    .style("opacity", .9);
                                div.html("<b>" + key.replaceAll("-", " ") + ":</b> " + value)
                                    .style("left", (event.pageX) + "px")
                                    .style("top", (event.pageY - 28) + "px");
                            })
                                .on("mouseout", function (d) {
                                    div.transition()
                                        .duration(500)
                                        .style("opacity", 0);
                                })
                                .attr("data-value", function (d) {
                                    return color(value);
                                })
                                .on("click", function (event, d) {
                                    let country = d.properties.name
                                    filterByCountry(country);
                                })
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
        });

    }

    function showOnList(debunk) {
        // Restore color
        d3.selectAll(".country").style('fill', function (x) {
            return this.dataset.value
        })
        if(horizontal === "1"){
            document.getElementById("carrousel-wrap").innerHTML += `<div id="related-debunks" style="position: absolute;top:150px;z-index: 100;background: whitesmoke;border-radius: 10px;box-shadow:-1px -1px 15px -1px rgba(0,0,0,0.85)">
           <p style="margin: 6px 0; font-family: Fira Sans Condensed,sans-serif;font-size: 20px;margin-top:15px;margin-bottom: 15px;text-align: center"><b>`
                + translations[lang].relatedDebunks +
                `</b><span id='close-related-debunks' style='cursor:pointer;float: right;margin-right: 15px'> 
           <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABo0lEQVRIid2UwS4DURSGP4YiaiE1xCu0fQnFWiRFWLAlbVTFQ2hiQeIJRCx4FQtRo02qEl7AgkRr0bG4ZzLXzR0zZdc/uZmb/5zznzNnzhkYdIwD68AN0AQ6wBvQBi7ENvJX8TXgBfBjzhOw2o/wMHCmCdwDFSAHTMrJCVcXnx5wIrGxOJegDrAXE+QAJaArMcdx4pua+EKSagQFLUlku8YIe77bh3iAssS2gJTNYZuw53pbpiU4o3EucGRwDvAgGkVbgmsxVgy+IrwHzMnxhDs0fKvCX9oSvIoxa/AzmqBn3F3DNy+2pi3BpxjTFtss4Uj6QAOYt/hNif09IIJeDxl3E7od1Nz3bFUKfBvZxt4il3+0SK/qTp7LRtCOJH1EzXtB7lnU5OkIYm9tb7Al2euokQuQAQ6Mal3hzDEN3s46phOEi1ayOcRgn3DRRqOcNsSpCyz2Ib4EfEnsSpzzqZakzM92mXBQlQfitSTVOFoSH7X+VdSEpOXkUVvsaX41Ev6uAxSBZ00g6rRI0JYopFDf5Qq1vR+oLW2g/jdFfvmgg4FvUC6Gkk15NCQAAAAASUVORK5CYII='/> </span></p>
                 <ul id="desinfo-list" style="list-style-type:disc;height: 250px;overflow: scroll;overflow-x: hidden;margin-top: 50px"></ul>`

            document.getElementById("debunked-desinfo").style.opacity = "0.2"
            document.getElementById("close-related-debunks").addEventListener("click", function (x) {
                loadPage()

            });
        }else{
            removeAllChildNodes(document.getElementById("desinfo-list"));
        }


        debunk.ocurrences.forEach(ocurrence => {
            document.getElementById("desinfo-list").innerHTML += '<li class="debunk-title" style="margin-bottom: 20px;"><a style="text-decoration: none;font-family:Fira Sans Condensed, sans-serif" href="' + ocurrence.debunkLink + '" target="_blank">' + ocurrence.debunkTitle + ' <b>(' + ocurrence.factchecker + ', ' + ocurrence.country.name + ') ' + '</b></a></li>';

            let countryName = ocurrence.country.name.replaceAll(" ", "-") // Remove spaces
                .replaceAll(".", "") // Remove points (bosnia and herz)
                .replaceAll("United-States-of-America", "United-States"); // Simplify name

            d3.select("[data-name=" + countryName + "]").style('fill', '#1a6985');
        });

    }

    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }

    function filterByCountry(country) {

        // Restore color
        d3.selectAll(".country").style('fill', function (x) {
            return this.dataset.value
        })

        document.querySelectorAll(".which-country").forEach(elem => {
            elem.innerHTML = translations[lang].preposition + " " + country + "<span id='close-country' style='cursor:pointer;'> <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABo0lEQVRIid2UwS4DURSGP4YiaiE1xCu0fQnFWiRFWLAlbVTFQ2hiQeIJRCx4FQtRo02qEl7AgkRr0bG4ZzLXzR0zZdc/uZmb/5zznzNnzhkYdIwD68AN0AQ6wBvQBi7ENvJX8TXgBfBjzhOw2o/wMHCmCdwDFSAHTMrJCVcXnx5wIrGxOJegDrAXE+QAJaArMcdx4pua+EKSagQFLUlku8YIe77bh3iAssS2gJTNYZuw53pbpiU4o3EucGRwDvAgGkVbgmsxVgy+IrwHzMnxhDs0fKvCX9oSvIoxa/AzmqBn3F3DNy+2pi3BpxjTFtss4Uj6QAOYt/hNif09IIJeDxl3E7od1Nz3bFUKfBvZxt4il3+0SK/qTp7LRtCOJH1EzXtB7lnU5OkIYm9tb7Al2euokQuQAQ6Mal3hzDEN3s46phOEi1ayOcRgn3DRRqOcNsSpCyz2Ib4EfEnsSpzzqZakzM92mXBQlQfitSTVOFoSH7X+VdSEpOXkUVvsaX41Ev6uAxSBZ00g6rRI0JYopFDf5Qq1vR+oLW2g/jdFfvmgg4FvUC6Gkk15NCQAAAAASUVORK5CYII='/> </span>";
            elem.style.background = "#e6e6e6";
            elem.style.padding = "4px";
            elem.style.borderRadius = "5px";

            document.getElementById("close-country").addEventListener("click", function (x) {
                loadPage()
            })
        });

        country = country.replaceAll("Bosnia and Herz.", "Bosnia and Herzegovina") // Remove points (bosnia and herz)
            .replaceAll("United States of America", "United-States") // Simplify name
            .replaceAll(" ", "-"); // Remove spaces


        // Select country
        d3.select("[data-name=" + country + "]").style('fill', '#1a6985');

        let slides;

        if (horizontal === "1") {
            slides = document.querySelectorAll('.svitlo-slide-horizontal');
        } else {
            slides = document.querySelectorAll(".svitlo-slide");
        }

        let visibleDebunks = false;
        let debunkCount = 0;
        slides.forEach(slide => {
            if (slide.dataset.countries.includes(country)) {
                visibleDebunks = true;
                slide.style.display = "flex";
                debunkCount++;
            } else {
                slide.style.display = "none";
            }
        })


        document.querySelectorAll(".no-disinfo").forEach(elem => {
            if (visibleDebunks) {
                elem.style.display = "none";
            } else {
                elem.style.display = "block";

            }
        });
        document.getElementById("total-disinfo").innerText = ": " + debunkCount + " FACT-CHECKS";
    }

    function setLanguage() {

        if (lang === 'en') {
            document.getElementById("lan-en").style.fontWeight = "bold"
            document.getElementById("lan-es").addEventListener("click", function (x) {
                document.getElementById("ukraine-facts").setAttribute("data-language", 'es');
                lang = 'es'
                loadPage()
            }, false);

        } else {
            document.getElementById("lan-es").style.fontWeight = "bold"
            document.getElementById("lan-en").addEventListener("click", function (x) {
                lang = 'en'
                loadPage()
            }, false);
        }
    }

}, false);
